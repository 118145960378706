import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Track } from '../../components/MusicPlayer/types';
import { AudioPlayer, RepeatState, TimeChangedEvent } from './AudioPlayer';

export type PlayerContextProps = {
  play: (track: Track) => void;
  stop: () => void;
  previous: () => void;
  toggleShuffle: () => void;
  cycleRepeat: () => void;
  next: () => void;
  setPlaylist: (tracks: Track[]) => void;
  seekTo: (second: number) => void;

  playlist: Track[];
  isLoading: boolean;
  currentTrack?: Track;
  isPlaying: boolean;
  shuffleOn: boolean;
  repeatState: RepeatState;
  currentTime: number;
  duration: number;
  analyzer?: AnalyserNode;
  audioContext?: AudioContext;
};

const  cast = <T,>(value: any) => value as T;

const PlayerContext = createContext<PlayerContextProps | null>(null);

function useAudioPlayer() {
  const [player, setPlayer] = useState<AudioPlayer>();


  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [playlist, setPlaylistFn] = useState<Track[]>([]);
  const [currentTrack, setCurrentTrack] = useState<Track>();
  const [shuffleOn, setShuffleOn] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [analyzer, setAnalyzer] = useState<AnalyserNode>();
  const [audioContext, setAudioContext] = useState<AudioContext>();
  const [repeatState, setRepeatState] = useLocalStorage("repeatState", RepeatState.Off);

  useEffect(() => {
    const newPlayer = new AudioPlayer();

    const playlistChangedListener = (e: Event) => void setPlaylistFn((e as CustomEvent).detail);
    const trackChangedListener = (e: Event) => void setCurrentTrack((e as CustomEvent).detail);
    const repeatStateChangedListener = (e: Event) => void setRepeatState((e as CustomEvent).detail);
    const shuffleChangedListener = (e: Event) => void setShuffleOn((e as CustomEvent).detail);
    const timeChangedListener = (e: Event) => {
      const evt: TimeChangedEvent = cast<CustomEvent>(e).detail;
      setCurrentTime(evt.currentTime);
      setDuration(evt.duration);
    };
    const stateChangedListener = (_: Event): void => {
      setIsLoading(newPlayer.isLoading);
      setIsPlaying(newPlayer.isPlaying);
    };

    newPlayer.addPlaylistChangedListener(playlistChangedListener);
    newPlayer.addStateChangedEventListener(stateChangedListener);
    newPlayer.addRepeatStateChangedEventListener(repeatStateChangedListener);
    newPlayer.addShuffleChangedEventListener(shuffleChangedListener);
    newPlayer.addTrackChangedEventListener(trackChangedListener);
    newPlayer.addTimeChangedEventListener(timeChangedListener);

    newPlayer.setRepeatState(repeatState);
    setAudioContext(newPlayer.audioContext);
    setAnalyzer(newPlayer.analyzer);
    setPlayer(newPlayer);

    return () => {
      newPlayer.removePlaylistChangedListener(playlistChangedListener);
      newPlayer.removeStateChangedEventListener(stateChangedListener);
      newPlayer.removeRepeatStateChangedEventListener(repeatStateChangedListener);
      newPlayer.removeShuffleChangedEventListener(shuffleChangedListener);
      newPlayer.removeTrackChangedEventListener(trackChangedListener);
      newPlayer.removeTimeChangedEventListener(timeChangedListener);
    };
  }, []);

  const setPlaylist = useCallback((tracks: Track[]) => {
    player?.setPlayList(tracks);
  }, [player]);

  const play = useCallback((track: Track) => {
    if (!player) return;

    if (track) {
      player.play(track);
    } else if (currentTrack) {
      player.play(currentTrack);
    } else if (playlist?.length) {
      player.play(playlist[0]);
    }
  }, [player, playlist, currentTrack]);

  const stop = useCallback(() => {
    player?.stop();
  }, [player]);

  const next = useCallback(() => {
    player?.next();
  }, [player]);

  const previous = useCallback(() => {
    player?.previous();
  }, [player]);

  const cycleRepeat = useCallback(() => {
    player?.cycleRepeat();
  }, [player]);

  const toggleShuffle = useCallback(() => {
    player?.toggleShuffle();
  }, [player]);

  const seekTo = useCallback((second: number) => {
    player?.seekTo(second);
  }, [player]);

  return {
    isPlaying,
    isLoading,
    playlist,
    currentTrack,
    shuffleOn,
    repeatState,
    currentTime,
    duration,
    analyzer,
    audioContext,

    play,
    stop,
    previous,
    next,
    setPlaylist,
    cycleRepeat,
    toggleShuffle,
    seekTo,
  };
}

function PlayerProvider({ children }: React.PropsWithChildren) {
  const playerState = useAudioPlayer();

  return (
    <PlayerContext.Provider value={playerState}>
      {children}
    </PlayerContext.Provider>
  );
}

function usePlayerContext(): PlayerContextProps {
  const context = useContext(PlayerContext);
  if (context == null) {
    throw new Error('usePlayerContext must be used within a PlayerProvider');
  }
  return context;
}

export { PlayerProvider, PlayerContext, usePlayerContext };
