import React from 'react';
import { alpha, Button } from '@mui/material';
import styled from 'styled-components';
import { usePlayerContext } from '../../contexts/PlayerContext/PlayerContext';

const StyledMusicList = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 24px 0 24px 24px;
  width: 300px;
  align-items: center;
  border-right: 1px solid #efefef;
`;

const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const StyleListItem = styled.li<{ $isActive: boolean; }>`
  list-style: none;
  ${({ theme, $isActive }) => $isActive && `
    background-color: ${alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity)};
  `}
`;

export default function MusicList() {
  const { currentTrack, playlist, play } = usePlayerContext();

  return (
    <StyledMusicList>
      <strong>Playlist</strong>
      <StyledList>
        {playlist.map((track) => (
          <StyleListItem key={track.id} $isActive={track.id === currentTrack?.id}>
            <Button fullWidth sx={{ justifyContent: 'start' }} onClick={() => play(track)}>{track.title}</Button>
          </StyleListItem>
        ))}
      </StyledList>
    </StyledMusicList>
  );
}
