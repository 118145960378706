import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import p5 from 'p5';
import {usePlayerContext} from '../../../contexts/PlayerContext/PlayerContext';
import {useContainerDimensions} from '../../../hooks/useContainerDimensions';

const StyledP5Visualization = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: black;
`;

const getWaveform = (analyzer: AnalyserNode) => {
  const wave = new Float32Array(analyzer.frequencyBinCount);
  analyzer.getFloatTimeDomainData(wave);
  return wave;
};

export default function P5Visualization() {
  const canvasRef = useRef(null);
  const { width, height } = useContainerDimensions(canvasRef);
  const { analyzer, audioContext, isPlaying } = usePlayerContext();

  useEffect(() => {
    if (!analyzer || !canvasRef.current || !audioContext) return;

    const sketch = new p5((p) => {
      const particles: Particle[] = [];

      p.setup = () => {
        p.createCanvas(width, height).parent(canvasRef.current);
        // p.rectMode(p.CENTER);
        p.angleMode(p.DEGREES);
      };

      p.draw = () => {
        p.background(0);
        p.translate(width/2, height/2);

        const amp = getEnergy(analyzer, audioContext, 20, 200);
        const wave = getWaveform(analyzer);

        // const alpha = p.map(amp, 0, 255, 240, 50);
        // p.fill(0, alpha);
        // p.noStroke();
        // p.rect(0, 0, width, height);

        p.stroke(255);
        p.strokeWeight(3);
        p.noFill();

        for (let t = -1; t <= 1; t += 2) {
          p.beginShape();
          for (let i = 0; i <= 180; i++) {
            const index = p.floor(p.map(i, 0, 180, 0, wave.length - 1));

            const radius = p.map(wave[index], -1, 1, 80, 160);

            const x = radius * p.sin(i) * t;
            const y = radius * p.cos(i);

            p.vertex(x, y);
          }
          p.endShape();
        }

        if (isPlaying) {
          const particle = new Particle(p);
          particles.push(particle);

          for (let i = particles.length-1; i >= 0; i--) {
            if (!particles[i].edges()) {
              particles[i].update(amp > 240);
              particles[i].show();
            } else {
              particles.splice(i, 1);
            }
          }
        }
      };
    });

    return () => {
      sketch.remove();
    };
  }, [canvasRef, width, height, analyzer, audioContext, isPlaying]);

  return (
    <StyledP5Visualization ref={canvasRef} />
  );
}

class Particle {
  pos: p5.Vector;
  vel: p5.Vector;
  acc: p5.Vector;
  w: number;
  p: p5;
  color: number[];

  constructor(p: p5) {
    this.pos = p5.Vector.random2D().mult(120);
    this.p = p;
    this.vel = this.p.createVector(0, 0);
    this.acc = this.pos.copy().mult(p.random(0.0001, 0.00001));
    this.w = this.p.random(2, 5);
    this.color = [this.p.random(150, 255), this.p.random(150, 255), this.p.random(150, 255)];
  }

  update = (beatDrop: boolean) => {
    this.vel.add(this.acc);
    this.pos.add(this.vel);
    if (beatDrop) {
      this.pos.add(this.vel);
      this.pos.add(this.vel);
      this.pos.add(this.vel);
      this.pos.add(this.vel);
    }
  };

  edges = () => {
    return this.pos.x < -this.p.width / 2 || this.pos.x > this.p.width / 2
      || this.pos.y < -this.p.height / 2 || this.pos.y > this.p.height / 2;
  };

  show = () => {
    this.p.noStroke();
    this.p.fill(this.color);
    this.p.ellipse(this.pos.x, this.pos.y, this.w);
  };
}

function getEnergy(analyzer: AnalyserNode, audioContext: AudioContext, frequency1: number, frequency2: number) {
  const nyquist = audioContext.sampleRate / 2;
  const wave = new Uint8Array(analyzer.frequencyBinCount);
  analyzer.getByteFrequencyData(wave);
  const lowIndex = Math.round((frequency1 / nyquist) * wave.length);
  const highIndex = Math.round((frequency2 / nyquist) * wave.length);

  let total = 0;
  let numFrequencies = 0;

  // add up all the values for the frequencies
  for (let i = lowIndex; i <= highIndex; i++) {
    total += Math.floor(wave[i]);
    numFrequencies += 1;
  }

  // divide by total number of frequencies
  return total / numFrequencies;
}
