import React from 'react';

import { IconButton } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import RepeatIcon from '@mui/icons-material/Repeat';
import RepeatOnIcon from '@mui/icons-material/RepeatOn';
import RepeatOneOnIcon from '@mui/icons-material/RepeatOneOn';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import ShuffleOnIcon from '@mui/icons-material/ShuffleOn';

export function PlayButton(props: any) {
  return (
    <IconButton aria-label="play" size="small" {...props}>
      <PlayCircleIcon sx={{ fontSize: 56 }} />
    </IconButton>
  );
}

export function PauseButton(props: any) {
  return (
    <IconButton aria-label="pause" size="small" {...props}>
      <PauseCircleIcon  sx={{ fontSize: 56 }} />
    </IconButton>
  );
}

export function PreviousButton(props: any) {
  return (
    <IconButton aria-label="previous" size="small" {...props}>
      <SkipPreviousIcon  sx={{ fontSize: 40 }} />
    </IconButton>
  );
}

export function NextButton(props: any) {
  return (
    <IconButton aria-label="next" size="small" {...props}>
      <SkipNextIcon  sx={{ fontSize: 40 }} />
    </IconButton>
  );
}

export function RepeatOffButton(props: any) {
  return (
    <IconButton aria-label="next" size="small" {...props}>
      <RepeatIcon sx={{ fontSize: 24 }} />
    </IconButton>
  );
}

export function RepeatOnButton(props: any) {
  return (
    <IconButton aria-label="next" size="small" {...props}>
      <RepeatOnIcon sx={{ fontSize: 24 }} />
    </IconButton>
  );
}

export function RepeatOneButton(props: any) {
  return (
    <IconButton aria-label="next" size="small" {...props}>
      <RepeatOneOnIcon sx={{ fontSize: 24 }} />
    </IconButton>
  );
}

export function ShuffleOffButton(props: any) {
  return (
    <IconButton aria-label="next" size="small" {...props}>
      <ShuffleIcon sx={{ fontSize: 24 }} />
    </IconButton>
  );
}

export function ShuffleOnButton(props: any) {
  return (
    <IconButton aria-label="next" size="small" {...props}>
      <ShuffleOnIcon sx={{ fontSize: 24 }} />
    </IconButton>
  );
}
