import React, {useEffect} from 'react';
import styled from 'styled-components';
import {usePlayerContext} from '../../contexts/PlayerContext/PlayerContext';
import LinearIndeterminate from '../LinearIndeterminate';
import MediaBar from './MediaBar';
import MusicList from './MusicList';
import {Track} from './types';
import MusicVisualization from './MusicVisualization';
import {Typography} from '@mui/material';

const StyledMusicPlayer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const StyledContent = styled.div`
  display: flex;
  flex-grow: 1;
`;

const StyledVisualization = styled.div`
  display: flex;
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing(4)};
`;

export default function MusicPlayer() {
  const { isLoading, setPlaylist } = usePlayerContext();

  useEffect(() => {
    setPlaylist(getTracks());
  }, [setPlaylist]);

  return (
    <StyledMusicPlayer>
      {isLoading && <LinearIndeterminate/>}
      <StyledContent>
        <MusicList />
        <StyledVisualization>
          <MusicVisualization />
        </StyledVisualization>
      </StyledContent>
      <MediaBar />
    </StyledMusicPlayer>
  );
}

function getTracks(): Track[] {
  return [
    { id: '1', title: 'Bing Me To Life', src: '/music/Evanescence_Bring-Me-To-Life.mp3' },
    { id: '2', title: 'Down', src: '/music/Tujamo-Down.mp3' },
    { id: '3', title: 'Lacrymosa', src: '/music/Evanescence-Lacrymosa.mp3' },
    { id: '4', title: 'Numb', src: '/music/Numb-Linkin Park.mp3' },
    { id: '5', title: 'Show Me A Sign', src: '/music/MKLA_Show-Me-A-Sign.mp3' },
  ];
}
