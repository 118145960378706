import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { usePlayerContext } from '../../../contexts/PlayerContext/PlayerContext';

const StyledCanvas = styled.canvas`
  display: flex;
  border: 1px solid #efefef;
  flex-grow: 1;
`;

const visualize = (canvas: HTMLCanvasElement, analyzer: AnalyserNode) => {
  const WIDTH = canvas.width;
  const HEIGHT = canvas.height;
  const canvasCtx = canvas.getContext("2d");

  if (canvasCtx == null) return;

  canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

  let drawVisual;
  const bufferLength = analyzer.fftSize;
  const draw = () => {
    drawVisual = requestAnimationFrame(draw);

    const dataArray = new Uint8Array(bufferLength);

    analyzer.getByteTimeDomainData(dataArray);

    canvasCtx.fillStyle = "#FFFFFF";
    canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

    canvasCtx.lineWidth = 1;
    canvasCtx.strokeStyle = "#000000";

    canvasCtx.beginPath();

    const sliceWidth = WIDTH / bufferLength;
    let x = 0;

    for (let i = 0; i < bufferLength; i++) {
      const v = dataArray[i] / 128.0;
      const y = (v * HEIGHT) / 2;

      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }

      x += sliceWidth;
    }

    canvasCtx.lineTo(canvas.width, canvas.height / 2);
    canvasCtx.stroke();
  };

  draw();

  return drawVisual;
};

export default function LineVisualization() {
  const ref = useRef<HTMLCanvasElement>(null);
  const { analyzer, isPlaying } = usePlayerContext();

  useEffect(() => {
    if (!isPlaying || !ref.current || !analyzer) return;

    const animationId = visualize(ref.current, analyzer);

    return () => {
      if (animationId != null) {
        cancelAnimationFrame(animationId);
      }
    };
  }, [ref, isPlaying, analyzer]);

  return (
    <StyledCanvas ref={ref}></StyledCanvas>
  );
}
