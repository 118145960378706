import React, { useEffect, useState } from 'react';
import { Box, Slider, useTheme } from '@mui/material';
import styled from 'styled-components';
import { usePlayerContext } from '../../contexts/PlayerContext/PlayerContext';
import { RepeatState } from '../../contexts/PlayerContext/AudioPlayer';
import { secondsToMinutesAndSeconds } from '../../contexts/PlayerContext/utils';
import {
  NextButton,
  PauseButton,
  PlayButton,
  PreviousButton,
  RepeatOffButton,
  RepeatOnButton,
  RepeatOneButton,
  ShuffleOffButton,
  ShuffleOnButton
} from './Buttons';

const StyledMediaBar = styled.footer`
  display: flex;
  padding: ${({ theme }) => theme.spacing(3)};
  justify-content: center;
  border-top: 1px solid #efefef;
`;

export default function MediaBar() {
  const [isSeeking, setIsSeeking] = useState<boolean>(false);
  const [seekValue, setSeekValue] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const {
    isPlaying,
    isLoading,
    currentTrack,
    shuffleOn,
    repeatState,
    currentTime,
    duration,
    play,
    stop,
    previous,
    next,
    cycleRepeat,
    toggleShuffle,
    seekTo,
  } = usePlayerContext();
  const theme = useTheme();

  useEffect(() => {
    setProgress(isSeeking ? seekValue : currentTime);
  }, [currentTime, seekValue, isSeeking]);

  return (
    <StyledMediaBar>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '500px',
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
          {shuffleOn && <ShuffleOnButton onClick={toggleShuffle} />}
          {!shuffleOn && <ShuffleOffButton onClick={toggleShuffle} />}
          <PreviousButton onClick={previous} />
          {isPlaying && <PauseButton onClick={stop} />}
          {/* @ts-ignore */}
          {!isPlaying && <PlayButton disabled={isLoading} onClick={() => play(currentTrack)} />}
          <NextButton onClick={next} />
          {repeatState === RepeatState.On && <RepeatOnButton onClick={cycleRepeat} />}
          {repeatState === RepeatState.Off && <RepeatOffButton onClick={cycleRepeat} />}
          {repeatState === RepeatState.One && <RepeatOneButton onClick={cycleRepeat} />}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
          <span>{secondsToMinutesAndSeconds(progress)}</span>
          <Slider
            min={0}
            max={duration}
            value={progress}
            onChange={(_, value) => {
              setIsSeeking(true);
              setSeekValue(value as number);
            }}
            onChangeCommitted={(_, value) => {
              setIsSeeking(false);
              setSeekValue(0);
              seekTo(value as number);
            }}
            sx={{
              color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
              height: 4,
              '& .MuiSlider-thumb': {
                width: 8,
                height: 8,
                transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                '&:before': {
                  boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                },
                '&:hover, &.Mui-focusVisible': {
                  boxShadow: `0px 0px 0px 8px ${
                    theme.palette.mode === 'dark'
                      ? 'rgb(255 255 255 / 16%)'
                      : 'rgb(0 0 0 / 16%)'
                  }`,
                },
                '&.Mui-active': {
                  width: 20,
                  height: 20,
                },
              },
              '& .MuiSlider-rail': {
                opacity: 0.28,
              },
            }}
          />
          <span>{secondsToMinutesAndSeconds(duration)}</span>
        </Box>
      </Box>
    </StyledMediaBar>
  );
}
