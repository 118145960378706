import React from 'react';
import styled from 'styled-components';
import {useLocalStorage} from '@uidotdev/usehooks';
import LineVisualization from './Visualizations/LineVisualization';
import P5Visualization from './Visualizations/P5Visualization';
import {Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography} from '@mui/material';

const StyledMusicVisualization = styled.div`
  display: flex;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacing(6)};
`;

enum VisualOptionEnum {
  MVP = 'mvp',
  P5 = 'p5',
}

type VisualOption = {
  Id: VisualOptionEnum;
  DisplayName: string;
}

const visualizationOptions: VisualOption[] = [
  { Id: VisualOptionEnum.MVP, DisplayName: 'V1 - Canvas Sine Wave Line' },
  { Id: VisualOptionEnum.P5, DisplayName: 'V2 - P5 Sine Wave Circle' },
]

function RenderVisualization({ option }: {option: VisualOptionEnum}) {
  switch(option) {
    case VisualOptionEnum.MVP:
      return <LineVisualization />;
    case VisualOptionEnum.P5:
      return <P5Visualization />;
  }
}

export default function MusicVisualization() {
  const [visuals, setVisuals] = useLocalStorage<VisualOptionEnum>('visual', VisualOptionEnum.MVP);

  return (
    <StyledMusicVisualization>
      <RenderVisualization option={visuals} />
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="visualization">Visualizations</InputLabel>
        <Select
          labelId="visualization"
          id="visualization"
          value={visuals}
          label="Visualization Option"
          onChange={(e) => setVisuals(e.target.value as VisualOptionEnum)}
        >
          {visualizationOptions.map((opt) => (
            <MenuItem key={opt.Id} value={opt.Id}>{opt.DisplayName}</MenuItem>
          ))}
        </Select>
        <FormHelperText>Select visualization</FormHelperText>
      </FormControl>
    </StyledMusicVisualization>
  )
}
