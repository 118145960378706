import { Mutex } from 'async-mutex';
import { Track } from '../../components/MusicPlayer/types';

export enum PlayerState {
  Initialized = 'initialized',
  Playing     = 'playing',
  Paused      = 'paused',
  Loading     = 'loading',
}

export enum RepeatState {
  On  = 'on',
  Off = 'off',
  One = 'one',
}

export interface TimeChangedEvent {
  currentTime: number;
  duration: number;
}

export class AudioPlayer extends EventTarget {
  private readonly PLAYLIST_CHANGED_EVENT = 'playlistChanged';
  private readonly REPEAT_STATE_CHANGED_EVENT = 'repeatStateChanged';
  private readonly SHUFFLE_CHANGED_EVENT = 'shuffleChanged';
  private readonly STATE_CHANGED_EVENT = 'stateChanged';
  private readonly TRACK_CHANGED_EVENT = 'trackChanged';
  private readonly TIME_CHANGED_EVENT = 'timeChanged';

  private readonly mutex = new Mutex();

  audioContext: AudioContext;
  source?: AudioBufferSourceNode;
  mediaElementSource: MediaElementAudioSourceNode;
  audioElement: HTMLAudioElement;
  currentTrack?: Track;
  playlist: Track[];
  startTime: number;
  startOffset: number;
  playerState: PlayerState;
  repeatState: RepeatState;
  shuffleOn: boolean;
  currentTime: number;
  duration: number;
  analyzer: AnalyserNode;

  constructor() {
    super();
    this.audioContext = new AudioContext();
    this.playlist = [];
    this.startTime = 0;
    this.startOffset = 0;
    this.playerState = PlayerState.Initialized;
    this.repeatState = RepeatState.Off;
    this.shuffleOn = false;
    this.currentTime = 0;
    this.duration = 0;
    this.audioElement = document.createElement('audio');

    const root = document.getElementById('root');
    if (root == null) {
      throw new Error('#root element not found');
    }

    root.append(this.audioElement);
    this.mediaElementSource = new MediaElementAudioSourceNode(this.audioContext, {
      mediaElement: this.audioElement,
    });

    this.initEventListeners();

    this.analyzer = this.audioContext.createAnalyser();
    this.mediaElementSource.connect(this.audioContext.destination);
    this.mediaElementSource.connect(this.analyzer);
  }

  play = (track: Track) => {
    const isNewTrack = this.currentTrack?.src != track.src;
    if (isNewTrack) {
      this.audioElement.src = track.src;
    }

    this.audioElement.play().then(() => {
      this.updateTrack(track);
    });
  };

  stop = () => {
    this.audioElement.pause();
  };

  seekTo = (second: number) => {
    this.audioElement.currentTime = second;
  };

  setPlayList = (tracks: Track[] = []) => {
    this.playlist = tracks;
    this.emit<Track[]>(this.PLAYLIST_CHANGED_EVENT, this.playlist);
  };

  updateTrack = (newTrack: Track) => {
    this.currentTrack = newTrack;
    this.emit<Track>(this.TRACK_CHANGED_EVENT, newTrack);
  };

  updateState = (newState: PlayerState) => {
    this.playerState = newState;
    this.emit<PlayerState>(this.STATE_CHANGED_EVENT, this.playerState);
  };

  cycleRepeat = async () => {
    await this.mutex.acquire();
    try {
      switch(this.repeatState) {
        case RepeatState.Off:
          this.repeatState = RepeatState.On;
          break;
        case RepeatState.On:
          this.repeatState = RepeatState.One;
          break;
        default:
          this.repeatState = RepeatState.Off;
      }
      this.emit<RepeatState>(this.REPEAT_STATE_CHANGED_EVENT, this.repeatState);
    } finally {
      this.mutex.release();
    }
  };

  toggleShuffle = async () => {
    await this.mutex.acquire();
    try {
      this.shuffleOn = !this.shuffleOn;
      this.emit<boolean>(this.SHUFFLE_CHANGED_EVENT, this.shuffleOn);
    } finally {
      this.mutex.release();
    }
  };

  previous = () => {
    if (!this.playlist.length) return;

    const prevIndex = this.wrapIndexMaybe(this.currentTackIndex-1);
    const prevRack = this.playlist[prevIndex];

    this.play(prevRack);
  };

  next = () => {
    if (!this.playlist.length) return;

    const nextIndex = this.wrapIndexMaybe(this.currentTackIndex+1);
    const nextRack = this.playlist[nextIndex];

    this.play(nextRack);
  };

  wrapIndexMaybe = (newIndex: number) => {
    const min = 0;
    const max = this.playlist.length;

    if (newIndex < 0) {
      // If the number is less than the minimum, wrap it to the maximum.
      newIndex = max - ((min - newIndex) % (max - min));
    } else if (newIndex >= max) {
      // If the number is greater than or equal to the maximum, wrap it to the minimum.
      newIndex = min + ((newIndex - min) % (max - min));
    }

    return newIndex;
  };

  initEventListeners = () => {
    this.audioElement.onended = () => {
      if (this.currentTackIndex < this.playlist.length - 1) {
        this.next();
      } else if (this.repeatState === RepeatState.On) {
        this.next();
      } else if (this.repeatState === RepeatState.One) {
        // @ts-ignore
        this.play(this.currentTrack);
      }
    };

    this.audioElement.onloadstart = () => {
      this.updateState(PlayerState.Loading);
    };

    this.audioElement.onpause = () => {
      this.updateState(PlayerState.Paused);
    };

    this.audioElement.onplaying = () => {
      this.updateState(PlayerState.Playing);
    };

    this.audioElement.ontimeupdate = () => {
      this.currentTime = isNaN(this.audioElement.currentTime) ? 0 : this.audioElement.currentTime;
      this.duration = isNaN(this.audioElement.duration) ? 0 : this.audioElement.duration;
      this.emit<TimeChangedEvent>(this.TIME_CHANGED_EVENT, {
        currentTime: this.currentTime,
        duration: this.duration,
      });
    };
  };

  setRepeatState = (newState: RepeatState) => {
    this.repeatState = newState;
  };

  get isLoading() {
    return this.playerState === PlayerState.Loading;
  };

  get isPlaying() {
    return this.playerState === PlayerState.Playing;
  };

  get currentTackIndex() {
    return this.currentTrack ? this.playlist.indexOf(this.currentTrack) : -1;
  };

  // event listeners

  addTimeChangedEventListener = (listener: EventListener) => {
    this.addEventListener(this.TIME_CHANGED_EVENT, listener);
  };

  removeTimeChangedEventListener = (listener: EventListener) => {
    this.removeEventListener(this.TIME_CHANGED_EVENT, listener);
  };

  addTrackChangedEventListener = (listener: EventListener) => {
    this.addEventListener(this.TRACK_CHANGED_EVENT, listener);
  };

  removeTrackChangedEventListener = (listener: EventListener) => {
    this.removeEventListener(this.TRACK_CHANGED_EVENT, listener);
  };

  addRepeatStateChangedEventListener = (listener: EventListener) => {
    this.addEventListener(this.REPEAT_STATE_CHANGED_EVENT, listener);
  };

  removeRepeatStateChangedEventListener = (listener: EventListener) => {
    this.removeEventListener(this.REPEAT_STATE_CHANGED_EVENT, listener);
  };

  addShuffleChangedEventListener = (listener: EventListener) => {
    this.addEventListener(this.SHUFFLE_CHANGED_EVENT, listener);
  };

  removeShuffleChangedEventListener = (listener: EventListener) => {
    this.removeEventListener(this.SHUFFLE_CHANGED_EVENT, listener);
  };

  addStateChangedEventListener = (listener: EventListener) => {
    this.addEventListener(this.STATE_CHANGED_EVENT, listener);
  };

  removeStateChangedEventListener = (listener: EventListener) => {
    this.removeEventListener(this.STATE_CHANGED_EVENT, listener);
  };

  addPlaylistChangedListener = (listener: EventListener) => {
    this.addEventListener(this.PLAYLIST_CHANGED_EVENT, listener);
  };

  removePlaylistChangedListener = (listener: EventListener) => {
    this.removeEventListener(this.PLAYLIST_CHANGED_EVENT, listener);
  };

  emit = <T>(eventName: string, detail: T) => {
    this.dispatchEvent(new CustomEvent<T>(eventName, {
      detail,
    }));
  };
}
