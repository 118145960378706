import React from 'react';
import MusicPlayer from './components/MusicPlayer';

function App() {
  return (
    <MusicPlayer />
  );
}

export default App;
